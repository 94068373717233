.spinner {
  position: absolute;
  top: 75%;
  left: 50%;
  display: block;
  width: 40px;
  height: 40px;
  border: 7px solid #a6a7d13b;
  border-radius: 50%;
  border-top-color: #88ade5;
  animation: spin 1s linear infinite;
  z-index: 100;
}

@keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
  }
}
